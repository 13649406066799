.k-dialog-titlebar {
  border-color: inherit;
  color: white;
  background-color: var(--color-main);
  padding: 9px 15px;
  font-size: 1.09375rem;
}
.k-dialog-buttongroup {
  justify-content: flex-end;
  padding: 10px 15px;
}
.k-window-content,
.k-prompt-container {
  padding: 8px 15px;
}
.k-window-title {
  font-size: 18px;
}
.modalLG {
  width: 900px;
}
.modalMD {
  width: 600px;
}
.modalSM {
  width: 450px;
}
.modalSX {
  width: 300px;
}

@media (max-width: 768px) {
  .modalLG {
    width: 100% !important;
  }
  .modalMD {
    width: 100% !important;
  }
  .modalSM {
    width: 100% !important;
  }
  .modalSX {
    width: 100% !important;
  }
  .k-dialog-wrapper .k-dialog {
    width: 90%;
  }
}

/* Maximum height */
@media (max-height: 40rem) {
  .heightMob {
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.userListGroup .k-panelbar > .k-item > .k-link,
.k-panelbar > .k-panelbar-header.k-expanded > .k-link {
  background-color: #fff;
}

.k-dialog-wrapper .k-dialog {
  max-height: 90%;
  .k-window-content {
    max-height: calc(100% - 47px);
    > div:not([class=k-dialog-buttongroup]) {
      max-height: calc(100% - 53px);
    } 
  }
}